<template>
    <section ref="main">
        <template v-if="data.image !== 'none'">
            <figure v-show="!limitView">
                <img v-if="data.image" :src="data.image" alt="">
                <span class="extension">{{data.extension}}</span>
            </figure>
        </template>
        <div class="data">
            <span class="title">{{data.title}}</span>
            <div class="extras">
                <template v-for="(extra, index) in data.description" :key="index">
                    <span :class="{light: extra.light}">{{extra.text}}</span>
                </template>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    props: {
        data: {
            type: Object
        }
    },

    data() {
        return {
            limitView: false,
        }
    },

    methods: {
        onDivResize(data) {
            const width =  data[0].contentRect.width
            if(width < 150) this.limitView = true
            else this.limitView = false
        }
    },

    mounted() {
        new ResizeObserver(this.onDivResize).observe(this.$refs.main)
    }
}
</script>

<style lang="scss" scoped>
section {
    width: 100%;
    display: flex;
    gap: 0.3rem;

    figure {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .extension {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: -2.5px;
            font-size: 8px;
            text-transform: lowercase;
        }
    }

    .data {
        width: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .extras {
            font-size: 0.7rem;

            span {
                width: 85%;
                white-space: nowrap;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                &.light {
                    color: $grey;
                    font-weight: 500;
                }
            }
        }
    }
}

</style>